import React from 'react';
import './Community.scss';
import Layout from '../../Components/Layout/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import { PortableText } from '@portabletext/react';

/**
 * @namespace Community
 * @function Community
 * @author Trevor Cash
 * @since 10/21/21
 * @version 1.0.0
 * @component
 */
export default function Community() {
  const data = useStaticQuery(graphql`
    query communityQuery {
      allSanityCommunity {
        nodes {
          image {
            asset {
              url
            }
          }
          _rawBody
        }
      }
      allSanitySmallGroups {
        nodes {
          title
          when
        }
      }
    }
  `);
  const community = data?.allSanityCommunity?.nodes?.[0];
  const smallGroups = data?.allSanitySmallGroups?.nodes;
  return (
    <Layout
      className='Giving-Container'
      title='Community'
      description='We have Bible studies, volunteering and special events for people of all ages. Learn more about Jesus, grow in your faith, and meet new friends by attending a small group study at First Church in West Jefferson, Ohio.'
    >
      <h1>Community</h1>
      <div className='Content'>
        <div className='Text'>
          {community?._rawBody && <PortableText value={community?._rawBody} />}
        </div>
        <div className='Image'>
          <img
            // src={content && urlFor(content?.image).width(550).height(360).url()}
            src={community?.image?.asset?.url}
            alt='Prayer Group'
            loading='lazy'
          />
        </div>
      </div>
      <div>
        <h2>Checkout our small groups!</h2>
        {smallGroups?.map((group, index) => (
          <div className='Small-Group' key={index}>
            <span>{group.title}</span>
            {group.when && <span>: {group.when}</span>}
          </div>
        ))}
      </div>
    </Layout>
  );
}
